// Legacy JQuery/Javascript
import "./article_posts.js";
import "./blog_posts.js";
// import "./cable.js";
import "./calendars.js";
import "./carousel_slides.js";
import "./categories.js";
import "./codemirror.js";
import "./contacts.js";
import "./container_rows.js";
import "./containers.js";
import "./containers_pages.js";
import "./content_group_items.js";
import "./content_items.js";
import "./country.js";
import "./datatables.js";
import "./documentation.js";
import "./image_batches.js";
import "./image_crops.js";
import "./image_galleries.js";
import "./image_modals.js";
import "./image_items.js";
import "./image_uploads.js";
import "./images.js";
import "./list_group_items.js";
import "./navigation_items.js";
import "./navigations.js";
import "./pages.js";
import "./page_contents.js";
import "./payment_cards.js";
import "./payment_methods.js";
import "./payment_types.js";
import "./rental_bookings.js";
import "./rental_payment_methods.js";
import "./row_columns.js";
import "./site_tags.js";
import "./sub_list_items.js";
import "./summernote-init.js";
import "./theme_colors.js";
import "./themes.js";
import "./tooltip.js";
import "./users.js";
import "./videos.js";
import "./watermarks.js";
