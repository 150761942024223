$(document).on('turbolinks:load', function() {
  if ($(".image-thumbnail").length) {
    let current_grid_id = 0;
    let image_grids = {};
    $.each($("imagegrid"), function() {
      image_grid = $.parseJSON($(this).attr('value'));
      image_grids[image_grid.id] = image_grid;
      current_grid_id = image_grid.id;
    });

    function show_image_controls() {
      $(".image-prev-btn").show();
      $(".image-next-btn").show();
    }

    function hide_image_controls() {
      $(".image-prev-btn").hide();
      $(".image-next-btn").hide();
    }

    $(document.body).on('mouseover', '.modal-body' ,function(){
      show_image_controls();
    });

    $(document.body).on('mouseout', '.modal-body' ,function(){
      hide_image_controls();
    });

    function set_modal(index,image_grid) {
      let item = image_grid.images[index];
      $("#modal_title").html(item.name);
      let srcset = $("#imageurls_"+item.id).attr('srcset');
      $("#modal_image").attr('srcset',srcset);
      let src = $("#imageurls_"+item.id).attr('src');
      $("#modal_image").attr('src',src);
      $("#modal_image").attr('alt',item.name);
      $("#current_grid_id").attr('value',image_grid.id);
      let prev_idx = Number(index) === 0 ? image_grid.images.length - 1 : Number(index) - 1;
      let next_idx = Number(index) === image_grid.images.length - 1 ? 0 : Number(index) + 1;
      $("#index_prev").attr('value',prev_idx);
      $("#index_next").attr('value',next_idx);
      $("#index_full").attr('value',index);
      if (image_grid.include_caption === true) {
        $("#modal_caption").html(item.caption);
      }
      if (image_grid.include_copyright === true) {
        $("#modal_copyright").html(item.copyright);
      }
    }

    $(document.body).on('click', '.image-next-btn' ,function(){
      let index = $("#index_next").attr('value');
      let current_grid_id = $("#current_grid_id").attr('value');
      let image_grid = image_grids[current_grid_id];
      set_modal(index,image_grid);
    });

    $(document.body).on('click', '.image-prev-btn' ,function(){
      let index = $("#index_prev").attr('value');
      let current_grid_id = $("#current_grid_id").attr('value');
      let image_grid = image_grids[current_grid_id];
      set_modal(index,image_grid);
    });

    $(document.body).on('click', '.image-thumbnail' ,function(){
      let item_info = this.id.split('_');
      current_grid_id = item_info[1];
      let index = item_info[2];
      let image_grid = image_grids[current_grid_id];
      set_modal(index,image_grid);
      hide_image_controls();
      $("#image_modal").modal('show');
    });

    $('#image_modal').on('hidden.bs.modal', function (e) {
      $("#modal_image").attr('srcset',"");
      $("#modal_image").attr('src',"");
      $("#modal_image").attr('alt',"");
      $("#modal_image").attr('title',"");
      $("#current_grid_id").attr('value','');
      $("#index_prev").attr('value','');
      $("#index_next").attr('value','');
      $("#modal_caption").html("");
      $("#modal_copyright").html("");
    });
  }
 });



