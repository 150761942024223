$(document).on('turbolinks:load', function() {
  var post_url;
  if ($('#row_columns').length) {
    post_url = $('#row_columns').data('sort-items-url');
    return $('#row_columns').sortable({
      axis: 'y',
      handle: '.handle',
      update: function() {
        return $.post(post_url, $(this).sortable('serialize'));
      }
    });
  }

  function hide_list() {
    $("#list_group_select").hide();
    $("#text_html_area").show();
    $("#placeholder_flag").val('1');
    $('#use_text_html').addClass('active');
    $('#use_list_group').removeClass('active');
  }

  function show_list() {
    $("#list_group_select").show();
    $("#text_html_area").hide();
    $("#placeholder_flag").val('');
    $('#use_list_group').addClass('active');
    $('#use_text_html').removeClass('active');
  }

  if ($("#use_list_group").length) {
    let text_enabled = $("#text_option_enabled").attr('value');
    if (text_enabled == 'true') {
      hide_list();
    } else {
      show_list();
    }
    $(document).on("click",'#use_list_group',function() {
      show_list();
    });
    $(document).on("click",'#use_text_html',function() {
      hide_list();
    });
  }
});
