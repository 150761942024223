$(document).on('turbolinks:load', function() {

  function load_image(batch_image) {
    let image = document.getElementById("batch_image_img_"+batch_image.batch_image_id);
    image.src = batch_image.image_url;
    console.log(batch_image.batch_image_id+" src="+image.src);
  }

  function set_complete(batch_image) {
    console.log("image "+batch_image.batch_image_id+" complete");
    load_image(batch_image);
    $("#batch_image_error_"+batch_image.batch_image_id).hide();
    $("#batch_image_pending_"+batch_image.batch_image_id).hide();
    $("#batch_image_"+batch_image.batch_image_id).removeClass('batch-card-pending');
    $("#batch_image_"+batch_image.batch_image_id).removeClass('batch-card-error');
    $("#batch_image_form_"+batch_image.batch_image_id).show();
  }

  function set_published(batch_image) {
    console.log("image "+batch_image.batch_image_id+" "+batch_image.name+" published");
    load_image(image_batch);
    $("#batch_image_publish_"+batch_image.batch_image_id).html('<i class="icon-picture"></i> Modify');
    $("#batch_image_pending_"+batch_image.batch_image_id).hide();
    $("#batch_image_error_"+batch_image.batch_image_id).hide();
    $("#batch_image_"+batch_image.batch_image_id).removeClass('batch-card-pending');
    $("#batch_image_"+batch_image.batch_image_id).removeClass('batch-card-error');
    $("#batch_image_form_"+batch_image.batch_image_id).show();
  }

  function set_error(batch_image) {
    console.log("image "+batch_image.batch_image_id+" "+batch_image.name+" error");
    $("#batch_image_"+batch_image.batch_image_id).removeClass('batch-card-complete');
    $("#batch_image_"+batch_image.batch_image_id).removeClass('batch-card-pending');
    $("#batch_image_"+batch_image.batch_image_id).addClass('batch-image-error');
    $("#batch_image_form_"+batch_image.batch_image_id).hide();
    $("#batch_image_pending_"+batch_image.batch_image_id).hide();
    $("#batch_image_error_"+batch_image.batch_image_id).html('<p><icon class="icon-alert"></icon>'+batch_image.status+'</p>');
    $("#batch_image_error_"+batch_image.batch_image_id).show();
  }

  function process_batch(batch_images) {
    let any_pending = false;
    batch_images.forEach((batch_image) => {
      let last_status = $("#batch_image_status_"+batch_image.batch_image_id).attr('value');
      console.log("image " + batch_image.batch_image_id + " status " + batch_image.status + " last status " + last_status);
      if (last_status != 'completed') {
        if (batch_image.status == 'complete' && batch_image.published == 'true') {
          set_published(batch_image.batch_image_id);
        } else if (batch_image.status == 'complete') {
          set_complete(batch_image);
        } else if (batch_image.status == 'pending') {
          any_pending = true;
        } else {
          set_error(batch_image);
        }
      }
      $("#batch_image_status_"+batch_image.batch_image_id).attr('value',batch_image.status);
    });
    if (any_pending === true) {
      setTimeout(() => {
        get_batch_status();
      }, 1000);
    }
  }

  function get_batch_status() {
    let batch_id = $("#image_batch_id").attr('value');
    let site_id = $("#site_id").attr('value');
    $.get('/admin/image_batch_images?image_batch_id='+batch_id+'&site_id='+site_id, function(data) {
      process_batch(data.batch_images);
    });
  }

  if ($("#image_batch_images").length) {
    let any_pending = false;
    $(".batch-card").each(function() {
      let batch_image_id = this.id.split('_')[2];
      let status = $("#batch_image_status_"+batch_image_id).attr('value');
      console.log("image " + batch_image_id + " status " + status);
      if (status == 'pending') {
        any_pending = true;
      }
    });

    if (any_pending === true) {
      get_batch_status();
    }
  }

});
