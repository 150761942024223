$(document).on('turbolinks:load', function() {
  if ($("#categories_tree").length) {
    $('.tree-button').on('click', function (e) {
      let button_id = this.id.split('_')[2];
      let icon = this.querySelector('icon');
      $("#category_tree_"+button_id).show();
      if (icon.className == 'icon-plus-squared') {
        $("#category_tree_"+button_id).show();
        icon.classList.remove("icon-plus-squared");
        icon.classList.add("icon-minus-squared");
      } else {
        $("#category_tree_"+button_id).hide();
        icon.classList.remove("icon-minus-squared");
        icon.classList.add("icon-plus-squared");
      }
    });

  }
});
